import React from 'react'
import Footer_Logo from "../images/Strands_Logo_green.png"

class Footer extends React.Component {
    render() {
        return (
            <div className="footerHome">
                <div className="footerLogo"><img src={Footer_Logo} alt="Strands" style={{ maxWidth: '111px', marginRight: '20px' }} /><a href="./"><strong>Start</strong></a><a href="https://strands.com/company/contact/"><strong>Contact</strong></a></div>
                <a href="https://www.strands.com"><strong>strands.com</strong></a>
            </div>
        )
    };
}

export default Footer
