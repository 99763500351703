import React from 'react'
import SiteLayout from '../components/SiteLayout'
import Footer from "../components/footer";
import { Row, Icon } from 'antd'
import { Link } from 'gatsby'
import BodyClassName from 'react-body-classname';
import Become_a_Partner from "../images/Strands_Become_a_Partner.png"

import getStarted_Icon from "../images/get-started-icon.png"
import apiCatalogue_Icon from "../images/api-catalogue-icon.png"
import tutorials_Icon from "../images/tutorials-icon.png"
import becomePartner_Icon from "../images/become-partner-icon.png"
import FAQ_Icon from "../images/faq-icon.png"


const IndexPage = ({ location }) => (
  <SiteLayout location={location} external>
    <BodyClassName className="bodyHome" />
    <div className="headerHome">
      <h1 style={{ textAlign: 'center' }}>Strands Developer Portal</h1>
      <h3 style={{ textAlign: 'center' }}>Build by using our open APIs to support all your needs</h3>
    </div>
    <Row type="flex" justify="center">

      <div className="flex-container homeLinks_01">

        <div className="flex-item homeLink">
          <img src={getStarted_Icon} className="title-icon" alt="Getting started icon" />
          <h2 className="getStarted-title">Get Started</h2>
          <p>Strands Developer Portal provides all information to understand, install and operate the Strands Finance Suite products.</p>
          <div className="button_module">
            <a className="button_home" href="/get-started">Register Now  »</a>
          </div>
        </div>

        <div className="flex-item homeLink">
          <img src={apiCatalogue_Icon} className="title-icon" alt="API Catalogue icon" />
          <h2 className="apiCatalogue-title">API Catalogue</h2>
          <p>Check out our Catalogue and choose the APIs that will enable you to develop new solutions.</p>
          <div className="button_module">
            <a className="button_home" href="/api-reference">Browse Catalogue  »</a>
          </div>
        </div>


        <div className="flex-item homeLink">
          <img src={tutorials_Icon} className="title-icon" alt="Tutorials icon" />
          <h2 className="tutorials-title">Tutorials</h2>
          <p>Our Tutorials will walk you through some of the most common user scenarios.</p>
          <div className="button_module">
            <a className="button_home" href="/budget-integration">Get Guidance  »</a>
          </div>
        </div>

      </div>
      <hr className="homeLink_02"></hr>
      <div className="flex-container homeLinks_02">

        <div className="flex-item homeLink_02">
          <Link to="/become-partner">
            <img src={becomePartner_Icon} className="title-icon" alt="Become a Partner icon" />
            <h2 className="becomePartner-title">Become a Partner</h2>
            <p>Have you developed a product our customers will love? Engage with them and help shape the future of Open Banking.</p>
          </Link>
        </div>

        <div className="flex-item homeLink_02">
          <Link to="/faq">
            <img src={FAQ_Icon} className="title-icon" alt="Frequently Asked Questions icon" />
            <h2 className="FAQ-title">FAQ</h2>
            <p>Strands 2.0 was released in May 2019. It was the second major upgrade since the platform launched. Learn how to upgrade.</p>
          </Link>
        </div>

      </div>
      <hr className="homeLink_02"></hr>
    </Row>
    <Footer />
  </SiteLayout>
)

export default IndexPage
